import React from "react";
import logo from "./asset/logo.svg";
import qrCode from "./asset/generate.png";
import "./App.css";

import { isMobile } from "./utils";

function App() {
  const year = new Date().getFullYear();
  return (
    <div className="App">
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        <span className="LogoWrapper">
          <img src={logo} className="LogoImage" alt="logo" />
        </span>

        <div className="Desc">
          <div className="App-title">TalkForge.AI</div>
          <div className="APP-desc ">
            Powered by artificial intelligence that makes language learning
            effective and easy
          </div>
          <div className={isMobile() ? "Btn-wrapper" : "Btn-wrapper PC"}>
            <div style={{ display: "inline-block", position: "relative" }}>
              <div
                className="App-store"
                onClick={() => {
                  if (isMobile()) {
                    window.location.href =
                      "https://apps.apple.com/cn/app/id6456946206";
                  }
                }}
              >
                <span
                  className={"Btn-text"}
                  style={{
                    position: "relative",
                    left: "3.3rem",
                    top: "2rem",
                  }}
                >
                  Download
                </span>
                <div
                  className={"Download-IOS"}
                  style={{
                    left: 0,
                    top: 0,
                    width: "10.5rem",
                    textAlign: "center",
                  }}
                >
                  <div
                    style={{
                      width: "fit-content",
                      margin: "0 auto",
                      background: "#fff",
                    }}
                  >
                    <img
                      src={qrCode}
                      width={116}
                      height={116}
                      alt="QR CODE"
                      style={{ margin: "4px" }}
                    />
                  </div>
                  <div style={{ color: "#fff", marginTop: "4px" }}>
                    扫描二维码下载
                  </div>
                </div>
              </div>
            </div>

            <div className="Google-play">
              <span
                className="Btn-text"
                style={{
                  position: "relative",
                  left: "3.83375rem",
                  top: "0.3125rem",
                }}
              >
                Coming soon
              </span>
            </div>
          </div>
        </div>
      </div>
      <footer className="Footer">
        {`© ${year} Immersively Inc. All rights reserved. | Contact us at
        dev@talkforge.ai`}
      </footer>
    </div>
  );
}

export default App;
